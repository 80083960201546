.bi.bi-diagram {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 0.07rem solid #dae1e7;
  border-radius: 0.25rem;
  box-shadow: 0 0.8rem 1rem -0.2rem rgba(0, 0, 0, 0.1),
    0 0.25rem 0.5rem -0.02rem rgba(0, 0, 0, 0.05);
  min-height: 100%;
  background-color: #f8fafc;
  position: relative;
  overflow: hidden;
}
.bi.bi-diagram .bi.bi-diagram-node {
  box-sizing: content-box;
  position: absolute;
  z-index: 50;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bi.bi-diagram .bi.bi-diagram-node .bi-port-wrapper {
  display: flex;
}
.bi.bi-diagram .bi.bi-diagram-node .bi-port-wrapper .bi-input-ports,
.bi.bi-diagram .bi.bi-diagram-node .bi-port-wrapper .bi-output-ports {
  flex: 1 1;
}
.bi.bi-diagram .bi.bi-diagram-node .bi-port-wrapper .bi-input-ports .bi-diagram-port,
.bi.bi-diagram .bi.bi-diagram-node .bi-port-wrapper .bi-output-ports .bi-diagram-port {
  transition: background-color 0.25s ease-in-out;
  backface-visibility: hidden;
  will-change: background-color;
  background-color: rgba(0, 0, 0, 0.08);
  width: 20px;
  width: 1.25rem;
  height: 20px;
  height: 1.25rem;
  margin-bottom: 0.25rem;
}
.bi.bi-diagram .bi.bi-diagram-node .bi-port-wrapper .bi-input-ports .bi-diagram-port:hover,
.bi.bi-diagram .bi.bi-diagram-node .bi-port-wrapper .bi-output-ports .bi-diagram-port:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.bi.bi-diagram .bi.bi-diagram-node .bi-port-wrapper .bi-output-ports .bi-diagram-port {
  margin-left: auto;
}
.bi.bi-diagram .bi.bi-diagram-node.bi-diagram-node-default {
  transition: box-shadow 0.25s ease-in-out, border 0.3s ease-out;
  backface-visibility: hidden;
  will-change: box-shadow, border;
  border: 0.07rem solid #8795a1;
  background-color: #dae1e7;
  color: #606f7b;
  border-radius: 0.25rem;
  box-shadow: 0 0.07rem 0.2rem 0 rgba(0, 0, 0, 0.1), 0 0.07rem 0.125rem 0 rgba(0, 0, 0, 0.06);
  padding: 0.5rem;
}
.bi.bi-diagram .bi.bi-diagram-node.bi-diagram-node-default:hover {
  box-shadow: 0 0.125rem 1rem -0.2rem rgba(0, 0, 0, 0.1),
    0 0.25rem 0.3rem -125rem rgba(0, 0, 0, 0.05);
}
.bi.bi-diagram .bi.bi-diagram-node.bi-diagram-node-default .bi-port-wrapper {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.bi.bi-diagram .bi-link-canvas-layer {
  pointer-events: none;
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-segment path {
  stroke: #dae1e7;
  stroke-width: 0.25rem;
  stroke-dasharray: 10, 2;
  fill: transparent;
  animation: BiDashSegmentAnimation 1s linear infinite;
}
.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-segment circle {
  stroke: #dae1e7;
  stroke-width: 0.15rem;
  fill: #88cdff;
}
.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link {
  pointer-events: stroke;
}
.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link .bi-link-path {
  stroke: blue;
  stroke-width: 0.25rem;
  pointer-events: stroke;
  fill: transparent;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link .bi-link-ghost {
  pointer-events: stroke;
  stroke: transparent;
  stroke-width: 1.2rem;
  fill: transparent;
  cursor: pointer;
}
.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link:hover .bi-link-path {
  stroke: #88cdff;
  stroke-dasharray: 10, 2;
  animation: BiDashSegmentAnimation 1s linear infinite;
}
.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link.readonly-link .bi-link-ghost {
  cursor: not-allowed;
}
.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link.readonly-link .bi-link-path {
  cursor: not-allowed;
  stroke: #b8c2cc;
  stroke-dasharray: none;
}
.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link.readonly-link:hover {
  stroke: #b8c2cc;
  stroke-dasharray: none;
  animation: none;
}
.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link foreignObject {
  width: 100%;
  height: 100%;
  overflow: visible;
  pointer-events: none;
}
.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link .bi-diagram-link-label {
  display: inline-block;
  color: #fff;
  background-color: #3d4852;
  border-radius: 0.25rem;
  padding: 0.25rem;
  text-align: center;
  font-size: 14px;
  font-size: 0.875rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 48px;
  min-width: 3rem;
  transform: translate(-50%, -50%);
}
@keyframes BiDashSegmentAnimation {
  0% {
    stroke-dashoffset: 24;
  }
  to {
    stroke-dashoffset: 0;
  }
}
